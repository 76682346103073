// focus-tracker.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusTrackerService {
  private isPageFocused = new Subject<boolean>();
  isPageFocused$ = this.isPageFocused.asObservable();

  constructor() {
    // Escuchar eventos focus y blur en window
    window.addEventListener('focus', () => this.setPageFocus(true));
    window.addEventListener('blur', () => this.setPageFocus(false));

    // Emitir el estado inicial
    this.setPageFocus(document.hasFocus());
  }

  private setPageFocus(focused: boolean) {
    console.log(`Enfoque actualizado: ${focused}`);
    this.isPageFocused.next(focused);
  }
}
