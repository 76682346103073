import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor() {}

  showAlert(options: {
    icon?: SweetAlertIcon,
    title?: string,
    text?: string,
    html?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      icon = 'info',
      title = 'Aviso',
      text = '',
      html = '',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    Swal.fire({
      icon,
      title,
      text,
      html,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }

  showExceptionAlert(exception: any, options: {
    title?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    let message = 'Ha ocurrido un error inesperado.';
    if (exception && exception.error && exception.error.message) {
      message = exception.error.message;
    }

    const {
      title = 'Error',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'error',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }

  showSuccessAlert(message: string, options: {
    title?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      title = 'Éxito',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'success',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }

  showWarningAlert(message: string, options: {
    title?: string,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    customClass?: any
  } = {}): void {
    const {
      title = 'Advertencia',
      confirmButtonText = 'Aceptar',
      confirmButtonColor = '#3085d6',
      cancelButtonText = 'Cancelar',
      showCancelButton = false,
      customClass = {}
    } = options;

    this.showAlert({
      icon: 'warning',
      title,
      text: message,
      confirmButtonText,
      confirmButtonColor,
      cancelButtonText,
      showCancelButton,
      customClass
    });
  }
}
