export class CajaBulto {
  folioBulto!: string;
  idBulto!: number;
  idCaja!: number
  sostenedor!: number;
  manifiesto!: boolean;
  tarjetas!: number;
  actual!: boolean;
  capacidad!: number;
  fecha!: string;
  conteoSD!: number;
  conteoSDBulto!: number;
}
