import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RegistroApertura } from "../models/registroApertura";
import { map, Observable } from "rxjs";
import { Constants } from "src/app/base/constants/constants";
import { FiltroApertura } from "../models/filtroApertura";
import { SD } from "../models/sd";
import { FormGroup } from "@angular/forms";
import { CajaBulto } from "../models/CajaBulto";

@Injectable({
  providedIn: 'root'
})
export class AperturaCajaService {
  constructor(
    private http: HttpClient
  ) { }

  getCajas( filtros: FiltroApertura): Observable<any | RegistroApertura[]>  {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    const params = this.obtenerParams(filtros);
    return this.http.get(url, {params: params});
  }

  obtenerParams(filtro: FiltroApertura) {
    let params = new HttpParams();

    if (null != filtro.folio) {
      params = params.set('folioCaja', filtro.folio);
    }

    if (null != filtro.bulto) {
      params = params.set('folioBulto', filtro.bulto);
    }
    if (null != filtro.estado) {
      params = params.set('estado', filtro.estado);
    }
    if (null != filtro.fecha) {
      params = params.set('fecha', filtro.fecha);
    }
    if (null != filtro.operador) {
      params = params.set('operador', filtro.operador);
    }
    if (null != filtro.rutSostenedor) {
      params = params.set('rutSostenedor', filtro.rutSostenedor);
    }
    return params;
  }

  folioCajaDisponible(folio: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/disponible/${folio}`;
    return this.http.get(url);
  }

  folioBultoDisponible(folio: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/disponible/bulto/${folio}`;
    return this.http.get(url);
  }

  getIDBulto(folio: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/bulto/${folio}`;
    return this.http.get(url);
  }

  obtenerBultos(): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/bultos`;
    return this.http.get(url);
  }

  obtenerDiferenciaSDdeBulto(folio: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/diferencia/bulto/${folio}`;
    return this.http.get(url);
  }

  crearCaja(caja: RegistroApertura) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    return this.http.post(url, caja);
  }

  editarCaja(caja: RegistroApertura) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    return this.http.put(url, caja);
  }

  borrarCaja(idCaja: number, folioCaja: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/id/${idCaja}/folio/${folioCaja}`;
    return this.http.delete(url);
  }

  consultarQR(qr: string, rut: number = null): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd`;
    return this.http.get(url, {params: {qr: qr, rutDocente:rut == null ? 0 : rut}});
  }

  insertarQR(sd: SD) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd`;
    return this.http.post(url, sd);
  }

  borrarSD(qr: string, idCaja: number, idBulto: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/${qr}/${idCaja}/${idBulto}`;
    return this.http.delete(url);
  }

  bultosDeCajas(idCaja: number, idBulto: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/caja/${idCaja}/bulto/${idBulto}`;
    return this.http.get(url);
  }

  comentarioSD(sd: SD): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/`;
    return this.http.put(url, sd);
  }

  listarSD (caja: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/caja/${caja}`;
    return this.http.get(url);
  }

  validaRut(rut: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/docente/rut/${rut}`;
    return this.http.get(url);
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  descargarCajas(url: string, filtro: FiltroApertura): Observable<any> {
    let params = this.obtenerParams(filtro);
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  obtenerBulto(folioBulto: string) {
    let params = new HttpParams();
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/caja/bulto/${folioBulto}`;
    return this.http.get(url);
  }

  obtenerDiferenciaSDBulto(caja: RegistroApertura) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/conteo/sd/caja/${caja.idCaja}/bulto/${caja.idBulto}`;
    return this.http.get(url);
  }

  sdRestantes(idCaja: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/contar/sd/caja/${idCaja}`;
    return this.http.get(url);
  }

  agregarCajaBulto(bultoForm: CajaBulto) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cajaBulto`;
    return this.http.post(url, bultoForm);
  }

  sellarCaja(id: number){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sellarCaja`;
    return this.http.put(url, id);

  }

  abrirCaja(id: number){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/abrirCaja`;
    return this.http.put(url, id);

  }

  cantidadSDCaja(id: number): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cantidadSDCaja`;
    let params = new HttpParams();
    params = params.set('idCaja',id)
    return this.http.get(url, {params: params});

  }

}
