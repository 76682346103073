import { CajaBulto } from "./CajaBulto";

export class RegistroApertura {
  idCaja!: number;
  idBulto!: number;
  folio!: string;
  bulto!: string;
  capacidad!: number;
  conteoSD!: number;
  fecha!: string;
  operador!: string;
  estado!: number;
  manifiesto!: boolean;
  rutSostenedor!: number;
  bultos!:string;
  bultoInicial!: CajaBulto
}
