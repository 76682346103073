export class RegistroSeguimiento {
  idFicha!: number;
  numSeguimiento!: string;
  rutSostenedor!: number;
  idRegion!: number;
  region!: string;
  comuna!: string;
  idEstadoEnvio!: number;
  estadoEnvio!: string;
  fechaEnvio!: string;
  idNomina!: number;
  idNominaPrevio!: number;
}
