<div class="h-full flex flex-col">
  <h3 class="text-md font-bold text-blue-700 mb-2">{{ edit ? 'Editar Corrección' : 'Nueva Corrección' }}</h3>

  <div *ngIf="loading && edit" class="flex flex-col justify-center items-center h-full">
    <mat-spinner diameter="40"></mat-spinner>
    <p class="text-gray-600 text-sm mt-2">Cargando...</p>
  </div>

  <div *ngIf="saving" class="flex flex-col justify-center items-center h-full">
    <mat-spinner diameter="40"></mat-spinner>
    <p class="text-gray-600 text-sm mt-2">Guardando...</p>
  </div>

  <div *ngIf="(!loading && !saving) || !edit" class="flex flex-col h-full">
    <!-- Sección de Agregar Problema -->
    <div class="bg-gray-50 p-2 rounded-md shadow-inner mb-2">
      <h4 class="text-sm font-bold text-gray-700 mb-1">Agregar un Problema</h4>

      <form [formGroup]="correccionForm" (ngSubmit)="agregarProblema()" class="space-y-2">
        <!-- Selector de Motivo -->
        <div>
          <mat-card class="w-full p-2">
            <mat-card-content>
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Motivo</mat-label>
                <mat-select id="motivo" formControlName="motivo" required (selectionChange)="onMotivoChange($event.value)">
                  <!-- Mostrar el nombre del motivo y agregar el tooltip con la descripción -->
                  <mat-option *ngFor="let motivo of motivosFiltrados" [value]="motivo.id" [matTooltip]="motivo.descripcion" matTooltipPosition="left">
                    {{ motivo.nombre }}
                  </mat-option>

                </mat-select>
                <mat-icon matSuffix>report_problem</mat-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>


        <!-- Campos de Valor Anterior y Valor Nuevo (Solo para ciertos motivos) -->
        <div *ngIf="mostrarCamposValor">
          <mat-card class="w-full p-2">
            <mat-card-content>
              <!-- Campo de Valor Anterior (Solo lectura) -->
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Valor Anterior</mat-label>
                <input matInput type="text" id="valorAnterior" formControlName="valorAnterior" class="block w-full bg-gray-200" readonly>
              </mat-form-field>

              <!-- Campo de Valor Nuevo (numérico o texto según motivo seleccionado) -->
              <mat-form-field appearance="outline" class="w-full mt-1">
                <mat-label>Valor Nuevo</mat-label>

                <!-- Input numérico para RUT y Correlativo -->
                <input *ngIf="selectedMotivo?.id === 3 || selectedMotivo?.id === 4"
                       matInput type="number" id="valorNuevo" formControlName="valorNuevo" class="block w-full no-spinner"
                       [min]="1" required>

                <!-- Input de texto para otros motivos solucionables -->
                <input *ngIf="selectedMotivo?.solucionable && selectedMotivo?.id !== 3 && selectedMotivo?.id !== 4"
                       matInput type="text" id="valorNuevo" formControlName="valorNuevo" class="block w-full" required>

                <mat-icon matSuffix>edit</mat-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>


        <!-- Botón de Agregar Problema -->
        <div class="flex justify-end">
          <button mat-raised-button color="primary" type="submit" class="text-sm"
                  [disabled]="mostrarCamposValor && correccionForm.get('valorNuevo')?.value === ''">
            <mat-icon>add_circle</mat-icon> Agregar
          </button>
        </div>

      </form>
    </div>

    <!-- Lista de Problemas Agregados -->
    <div *ngIf="problemas.length > 0" class="flex-grow flex flex-col">
      <h4 class="text-sm font-bold text-gray-700 mb-1">Problemas Agregados</h4>
      <div class="overflow-y-auto" style="max-height: 350px;">
        <ul class="space-y-1">

          <li *ngFor="let problema of problemas; let i = index" class="bg-white rounded-md shadow-sm mb-2 p-2 flex flex-col">
            <!-- Encabezado con el icono, nombre del problema, botón de información y botón para eliminar -->
            <div class="flex items-center justify-between">
              <!-- Izquierda: Ícono y nombre del problema -->
              <div class="flex items-center space-x-2">
                <mat-icon class="text-blue-500 text-base">report_problem</mat-icon>
                <p class="text-sm font-bold text-gray-800">{{ problema.motivoNombre }}</p>
              </div>

              <!-- Botón para eliminar -->
              <button mat-icon-button color="warn" class="p-0" (click)="eliminarProblema(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <!-- Detalles adicionales: "No Solucionable" y valores (anterior y nuevo), con indentado -->
            <div class="ml-6 mt-1">
              <!-- "No Solucionable" si aplica -->
              <div *ngIf="!problema.solucionable" class="flex items-center space-x-1">
                <p class="text-xs text-red-600 font-semibold">No Solucionable</p>
                <button mat-icon-button class="p-0" [matTooltip]="'Este problema no puede ser solucionado por el operador. La revisión se marcará como fallida.'" matTooltipPosition="above">
                  <mat-icon class="text-red-500 text-xs">help_outline</mat-icon>
                </button>
              </div>

              <!-- Valores Anterior y Nuevo cuando es solucionable -->
              <div *ngIf="problema.solucionable" class="text-xs text-gray-600">
                <p><span class="font-bold">Valor Anterior:</span> {{ problema.valorAnterior ?? 'N/A' }}</p>
                <p><span class="font-bold">Valor Nuevo:</span> {{ problema.valorNuevo ?? 'N/A' }}</p>
              </div>
            </div>
          </li>



        </ul>
      </div>
    </div>

    <!-- Botones Guardar y Cancelar -->
    <div *ngIf="!saving" class="flex justify-end space-x-2 mt-2">
      <button mat-stroked-button color="warn" type="button" class="text-sm" (click)="onCancelar()">
        <mat-icon>cancel</mat-icon> Cancelar
      </button>
      <button mat-raised-button color="primary" type="button" class="text-sm" (click)="onGuardar()" [disabled]="problemas.length == 0">
        <mat-icon>save</mat-icon> Guardar
      </button>
    </div>
  </div>
</div>
