import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Animate,
  Ripple,
  Sidenav,
  Tooltip,
  initTE
} from "tw-elements";
import { AuthService } from '../../services/auth.service';
import { AutorizacionesService } from '../../services/autorizaciones.service';
import { fader } from '../../services/route-animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    fader
  ]
})
export class HomeComponent implements OnInit {
  email: string = "usuario@iie.cl"
  grupos!: string[];
  sidebarCollapsed: boolean = false;
  instance!: any;
  @ViewChild('colapsable') colapsable: ElementRef;
  menuActive: string = "inicio";
  firstTime: boolean = true;
  indiceCargasMasivas: number;
  inicio: {
    "routerlink": "inicio",
    "label": "Inicio",
    "icon": "../../../../assets/images/sidebar/house.svg"
  };
  private routerSubscription: Subscription;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  modulo2024: { routerlink: string, label: string, icon: string, color: string }[] = [
    {
      "routerlink": "ficha-docente",
      "label": "Estado Docente",
      "icon": "ED",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "registro-encargados-evaluacion-list",
      "label": "Encargados Evaluación",
      "icon": "EE",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "representantes-legales",
      "label": "Representantes Legales",
      "icon": "RL",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "oficios",
      "label": "Oficios",
      "icon": "OF",
      "color" : "rgba(15,105,180,0.33)"
    },

    {
      "routerlink": "eventos",
      "label": "Eventos Docentemás",
      "icon": "EV",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "cierre-procesos-docentemas",
      "label": "Cierre Procesos",
      "icon": "PR",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "mantenedor-usuarios-list",
      "label": "Usuarios DocenteMás",
      "icon": "UD",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "portafolio-actual",
      "label": "Avance Portafolio",
      "icon": "AP",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "portafolio-docentemas",
      "label": "Portafolio Docentemás",
      "icon": "PF",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "noticias", //solo en dev y local
      "label": "Noticias",
      "icon": "NT",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "cargas-masivas", //solo en dev y local
      "label": "Cargas Masivas",
      "icon": "CM",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "descarga-informes",
      "label": "Descarga Informes",
      "icon": "DI",
      "color" : "rgba(15,105,180,0.33)"
    },
  ];

  moduloTracklog: { routerlink: string, label: string, icon: string, color: string }[] = [
    {
      "routerlink": "tracklog/ficha-seguimiento",
      "label": "Ficha Seguimiento",
      "icon": "FS",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "tracklog/qa-grabaciones",
      "label": "Revisiones Grabaciones",
      "icon": "RG",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "tracklog/recepcion-bulto-grabaciones",
      "label": "Recepción de Bultos",
      "icon": "RB",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "tracklog/apertura-caja-grabaciones",
      "label": "Apertura de Cajas y SD",
      "icon": "SD",
      "color" : "rgba(15,105,180,0.33)"
    },
  ];

  moduloAdministracion: { routerlink: string, label: string, icon: string, color: string }[] = [
    {
      "routerlink": "mantenedor-usuarios-cognito",
      "label": "Usuarios Operaciones",
      "icon": "OP",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "gestor-usuarios-mda",
      "label": "Usuarios MDA",
      "icon": "UM",
      "color" : "rgba(15,105,180,0.33)"
    },
  ];

  moduloRegistroHistorico: { routerlink: string, label: string, icon: string, color: string }[] = [
    {
      "routerlink": "evaluaciones-historicas",
      "label": "Evaluaciones",
      "icon": "EH",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "evaluaciones-historicas-extendida",
      "label": "Evaluaciones Extendidas",
      "icon": "EX",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "registro-docente-list",
      "label": "Estado Docente",
      "icon": "DH",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "tickets-mda",
      "label": "Tickets MDA",
      "icon": "TK",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "portafolio2023",
      "label": "Portafolio 2023",
      "icon": "P2",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "historial-llamadas",
      "label": "Historial de Llamadas",
      "icon": "HL",
      "color" : "rgba(15,105,180,0.33)"
    },
    {
      "routerlink": "reportes",
      "label": "Reportes",
      "icon": "RE",
      "color" : "rgba(15,105,180,0.33)"
    },
  ];

  allModules: { routerlink: string, label: string, icon: string, color: string }[]  = this.modulo2024;

  env: string = 'dev';

  stopBouncingToggleMenu() {
    this.firstTime = false;
  }

  constructor(
    @Inject(Router) private router: Router,
    private authService: AuthService,
    private permisoPara: AutorizacionesService
  ) { }

  showMenu(menu: string) {
    this.menuActive = menu;
  }

  slimClick() {
    let instance = Sidenav.getInstance(
      document.getElementById("sidenav-4")
    );
    if (!instance) window.location.reload();
    instance.toggleSlim();
    this.sidebarCollapsed = instance._slimCollapsed;
  }


  seleccionarMenu(id: string) {
    const menu: HTMLElement = document.getElementById(id);
    menu.classList.toggle('active');
  }

  nameEnv () {
    const host = environment.host;
    return host.includes('dev') ? 'dev' : host.includes('cert') ? 'cert' : 'prod';
  }


  ngOnInit(): void {
    this.env = this.nameEnv();
    initTE({ Sidenav, Ripple, Tooltip, Animate }, { allowReinits: true });
    const sidenav = document.getElementById("sidenav-4");
    this.instance = Sidenav.getInstance(sidenav);
    this.instance.show();
    this.slimClick();

    this.allModules = this.allModules.concat( this.moduloRegistroHistorico, this.moduloTracklog, this.moduloAdministracion);

    const currentPath = this.router.url;
    this.seleccionarMenuActual(currentPath);
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.url;
        this.seleccionarMenuActual(path);
      }
    });

    if (this.permisoPara.containRol(['ROLE_ADMIN', 'ADM_REGISTRO'])) {
      if(!environment.isDevOrLocal){
        this.modulo2024 = this.asignaModulos([ 'ED', 'EE', 'RL', 'OF', 'EV', 'UD', 'AP', 'PF', 'DI' ], this.modulo2024);
        this.moduloRegistroHistorico = this.asignaModulos(['EH', 'EX', 'DH', 'P2', 'TK','HL', 'RE'], this.moduloRegistroHistorico);
      }
      this.moduloTracklog = this.asignaModulos(['FS', 'RG', 'RB', 'SD'], this.moduloTracklog);
      return;
    }
    if (this.permisoPara.containRol(['OP_CPEIP'] )) {
      this.modulo2024 = this.asignaModulos(['ED','EE','RL', 'OF'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['EH', 'EX', 'DH'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = undefined;
      return;
    }
    if (this.permisoPara.containRol(['ADM_MDA'])) {
      this.modulo2024 = this.asignaModulos(['ED','EE','RL'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['EH', 'DH', 'P2', 'TK','HL', 'RE'], this.moduloRegistroHistorico);
      this.moduloAdministracion = this.asignaModulos(['UM'], this.moduloAdministracion);
      this.moduloTracklog = undefined;
      return;
    }
    if (this.permisoPara.containRol(['OP_MDA'] )) {
      this.modulo2024 = this.asignaModulos(['ED','EE', 'RL'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['EH', 'DH', 'TK','HL'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = undefined;
      return;
    }
    if (this.permisoPara.containRol(['ADM_INSTRUMENTOS'] )) {
      this.modulo2024 = this.asignaModulos(['ED','EE', 'RL', 'OF', 'EV', 'AP', 'PF', 'DI'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['EH', 'EX', 'DH', 'P2', 'TK', 'RE'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = undefined;
      return;
    }
    if (this.permisoPara.containRol(['COORDINADOR_GRABACIONES'])) {
      this.modulo2024 = undefined;
      this.moduloRegistroHistorico = undefined;
      this.moduloAdministracion = undefined;
      this.moduloTracklog = this.asignaModulos(['FS','RB'], this.moduloTracklog);
      return;
    }
    if (this.permisoPara.containRol(['EJECUTIVO_GRABACIONES'])) {
      this.modulo2024 = this.asignaModulos(['ED','EE', 'RL'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['DH'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = this.asignaModulos(['FS'], this.moduloTracklog);
      return;
    }
    if (this.permisoPara.containRol(['READER_GRABACIONES'])) {
      this.modulo2024 = undefined;
      this.moduloRegistroHistorico = undefined;
      this.moduloAdministracion = undefined;
      this.moduloTracklog = this.asignaModulos(['FS', 'RB', 'SD','RG'], this.moduloTracklog);
      return;
    }
    if (this.permisoPara.containRol(['ADM_GRABACIONES'])) {
      this.modulo2024 = this.asignaModulos(['ED','EE', 'RL'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['DH','RE'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = this.asignaModulos(['FS', 'RB', 'SD','RG'], this.moduloTracklog);
      return;
    }
    if (this.permisoPara.containRol(['OPERADOR_GRABACIONES'])) {
      this.modulo2024 = this.asignaModulos(['ED','EE', 'RL'], this.modulo2024);
      this.moduloRegistroHistorico = this.asignaModulos(['DH'], this.moduloRegistroHistorico);
      this.moduloAdministracion = undefined;
      this.moduloTracklog = this.asignaModulos(['FS', 'RB', 'SD','RG'], this.moduloTracklog);
      return;
    }
    else {
      this.modulo2024 = undefined;
      this.moduloRegistroHistorico = undefined;
      this.moduloAdministracion = undefined;
      this.moduloTracklog = undefined;
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  seleccionarMenuActual(path: string) {
    path = path.replace("/private/","");
    const findInModulo2024 = this.allModules.filter(item => item.routerlink === path)
    if (findInModulo2024) {
      this.menuActive = findInModulo2024[0]?.icon;
      return;
    }
    this.menuActive = null;
  }

  asignaModulos(
    moduloMenu: string[],
    menu: { routerlink: string, label: string, icon: string, color: string }[]):
    { routerlink: string, label: string, icon: string, color: string }[]
  {
      return menu.filter((item, index) => moduloMenu.indexOf(item.icon) > -1 );
  }

  cerrarSesion(): void {
    this.authService.logout();
  }
}
