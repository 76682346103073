<div class="fixed inset-0 flex items-center justify-center z-50 p-4 sm:p-0 overflow-y-auto w-full h-full">
  <!-- Contenedor Principal -->
  <div class="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg max-h-screen w-full lg:w-4/5 xl:w-[85%] overflow-y-auto"
       style="min-height: 90vh; max-height: 90vh">

    <!-- Spinner de carga centrado mientras isLoading es verdadero -->
    <div *ngIf="isLoading || isSaving" class="flex flex-col items-center justify-center bg-white w-full h-full overflow-y-hidden"
         style="min-height: 10vh; height: 80vh; position: relative;">
      <div>
        <mat-spinner></mat-spinner>
      </div>
      <div class="mt-4 text-center">
        <div *ngIf="isLoading && !isSaving">Cargando información de la revisión...</div>
        <div *ngIf="isSaving">Guardando...</div>
      </div>
    </div>



    <!-- Mostrar contenido cuando isLoading sea falso -->
    <div *ngIf="!isLoading && !isSaving" class="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg max-h-screen w-full overflow-y-auto"
         style="min-height: 80vh">
      <!-- Panel Izquierdo: 70% del ancho total -->
      <div class="lg:flex-grow lg:basis-7/10 p-6 flex flex-col bg-gray-100 h-full">
        <!-- Encabezado del Panel Izquierdo -->
        <div class="flex items-center justify-between mb-4">
          <h2 class="text-xl font-bold text-blue-700 flex items-center">
            <mat-icon class="mr-2">assignment</mat-icon>
            Detalles revisión #{{revisionId}}
            <span *ngIf="readonly" class="flex items-center text-gray-500 ml-4">
              <mat-icon class="align-middle mr-1">lock</mat-icon>
              <span>Sólo lectura</span>
            </span>
          </h2>
        </div>

        <!-- Cuadro de advertencia de solo lectura -->
        <div *ngIf="readonly || readonlyUser" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 rounded-lg flex items-center" role="alert">
          <mat-icon class="mr-2">warning</mat-icon>
          <div>
            <p class="font-bold">Modo Sólo Lectura.</p>
            <p>
            <span *ngIf="revisionAssignedToAnotherOperator">Esta revisión está asignada a otro operador.</span>
            <span *ngIf="errorLoading">Ocurrió un error al intentar cargar información de esta revisión.</span>
            <span> No puedes realizar cambios.</span>
            </p>
          </div>
        </div>

        <!-- Pestañas: Reproductor de Video y Galería -->
        <mat-tab-group class="flex-grow mb-6" style="height: 50%">
          <mat-tab label="Reproductor de Video">
            <div class="w-full pb-[56.25%] bg-gray-200 rounded-lg overflow-hidden mb-4">
              <video controls class="absolute top-0 left-0 w-full h-full bg-black rounded-md object-contain"
                     *ngIf="detalleRevision?.videoURL">
                <source [src]="detalleRevision.videoURL" type="video/mp4" />
                Tu navegador no soporta la reproducción de este video.
              </video>
            </div>
          </mat-tab>

          <mat-tab [label]="'Galería de Screenshots (' + (detalleRevision.screenshots?.length || 0) + ')'">
            <div class="bg-gray-100 p-4 rounded-md w-full overflow-auto flex-grow" style="max-height: 100%">
              <mat-grid-list cols="3" rowHeight="120px" gutterSize="10px">
                <mat-grid-tile *ngFor="let screenshot of detalleRevision.screenshots">
                  <div class="flex flex-col items-center space-y-1">
                    <img [src]="screenshot.presignedURL"
                         class="w-full h-20 bg-gray-300 rounded-md cursor-pointer object-cover"
                         (click)="openModal(screenshot)" />
                    <p class="text-sm text-center whitespace-nowrap">
                      {{ formatTimeFromSeconds(screenshot.timeSeconds) }}<br />
                      (Frame: {{ screenshot.frameNumber }})
                    </p>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </mat-tab>
        </mat-tab-group>

        <!-- Información de la Revisión -->

        <mat-tab-group class="h-[100%]">
          <!-- Tab Detalles -->
          <mat-tab label="Detalles">
            <!-- Información de la Revisión -->
            <div class="bg-white shadow-md p-4 rounded-md mt-4">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">report</mat-icon>
                    Estado revisión:
                  </p>
                  <p class="text-sm text-gray-900"
                     [ngStyle]="{'color': service.getEstadoById(detalleRevision.estadoId).display_color}">
                    {{ service.getEstadoById(detalleRevision.estadoId).display_text }}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">badge</mat-icon>
                    Rut Docente:
                  </p>
                  <p class="text-sm text-gray-900">
                    {{ detalleRevision.rutDocente }}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">movie</mat-icon>
                    Archivo de Video:
                  </p>
                  <p class="text-sm text-gray-900">
                    {{ detalleRevision.archivoVideo }}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">format_list_numbered</mat-icon>
                    Número correlativo:
                  </p>
                  <p class="text-sm text-gray-900">
                    {{ detalleRevision.ordenCorrelativo }}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">supervisor_account</mat-icon>
                    Operador asignado:
                  </p>
                  <p class="text-sm text-gray-900">{{detalleRevision.operadorId ? detalleRevision.operadorId : 'Sin operador asignado'}}</p>
                </div>
                <div>
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">calendar_today</mat-icon>
                    Fecha de creación de Revisión:
                  </p>
                  <p class="text-sm text-gray-900">
                    {{ detalleRevision.fechaRevision | date:'dd-MM-yyyy HH:mm:ss' }}
                  </p>
                </div>

                <!-- Mostrar información de archivado si archivado es true -->
                <div *ngIf="detalleRevision.archivado">
                  <p class="text-sm font-bold text-gray-700 flex items-center">
                    <mat-icon class="mr-2">archive</mat-icon>
                    Archivado
                  </p>
                  <p class="text-sm text-red-600">
                    Archivado
                  </p>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Tab Conflictos -->
          <mat-tab [label]="'Conflictos (' + (detalleRevision.conflictos?.length || 0) + ')'">
            <div class="p-4 max-h-96 overflow-y-auto">
              <div *ngIf="detalleRevision.conflictos && detalleRevision.conflictos.length > 0; else noConflictos">
                <div *ngFor="let conflicto of detalleRevision.conflictos" class="bg-white border border-red-400 rounded-lg mb-3 p-3 shadow-sm">
                  <div class="flex items-center gap-2 mb-2">
                    <mat-icon [ngClass]="{'text-red-600': !bypassRevisionConfitcs, 'text-orange-600': bypassRevisionConfitcs}">warning</mat-icon>
                    <h3 class="text-base font-bold " [ngClass]="{'text-red-600': !bypassRevisionConfitcs, 'text-orange-600': bypassRevisionConfitcs}" >{{ conflicto.nombreParaMostrar }}</h3>
                  </div>
                  <div class="text-sm text-gray-700">
                    <p class="mb-1"><strong>Campo:</strong> {{ conflicto.nombreCampo }}</p>
                    <p class="mb-1"><strong>Valor del Campo:</strong> {{ conflicto.valorCampo }}</p>
                    <p class="mb-1"><strong>Detalle:</strong> {{ conflicto.detalle }}</p>
                    <p class="mb-1"><strong>Revisiones Relacionadas:</strong> {{ conflicto.revisionIds.join(', ') }}</p>
                  </div>
                </div>
              </div>
              <ng-template #noConflictos>
                <p class="text-sm text-gray-700">No se han detectado conflictos para esta revisión.</p>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>

      </div>

      <!-- Panel Derecho: 30% del ancho total -->
      <div class="lg:flex-grow bg-gray-100 lg:w-1/12 border-t lg:border-t-0 lg:border-l p-4 flex flex-col h-full"
           style="min-width: 30%">

        <!-- Encabezado del Panel Derecho -->
        <div class="flex items-center justify-between mb-4">
          <h3 class="text-lg font-bold text-blue-700 flex items-center">
            <mat-icon class="mr-2">edit</mat-icon>
            Correcciones
          </h3>
        </div>

        <!-- Mostrar spinner cuando deletingCorrection es verdadero -->
        <div *ngIf="deletingCorrection"  class="flex flex-col items-center justify-center bg-white w-full h-full overflow-y-hidden"
               style="min-height: 10vh; height: 80vh; position: relative;">
            <div>
              <mat-spinner></mat-spinner>
            </div>
            <div class="mt-4 text-center">
              <div>Guardando...</div>
            </div>
              </div>
        <div *ngIf="!deletingCorrection">

          <!-- Formulario de nueva corrección o edición -->
          <ng-container *ngIf="isCreatingNewCorrection || isEditingCorrection">
            <app-nueva-correccion class="flex-grow"
                                  [revisionData]="this.detalleRevision"
                                  [edit]="isEditingCorrection"
                                  (guardarCorreccion)="saveCorrection($event)"
                                  (cancelarCorreccion)="cancelNewCorrection()"></app-nueva-correccion>
          </ng-container>

          <!-- Mostrar botón de nueva corrección y correcciones existentes -->
          <ng-container *ngIf="!isCreatingNewCorrection && !isEditingCorrection">

            <!-- Botón "Nueva corrección" (bloqueado si es readonly) -->
            <div *ngIf="this.correccion == null && (detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1)" class="mb-4">
              <button type="button"
                      (click)="startNewCorrection()"
                      class="w-full text-white font-medium py-2 rounded-lg flex items-center justify-center shadow-md transition-transform transform hover:scale-105"
                      [class.bg-green-600]="!readonly"
                      [class.hover\:bg-green-700]="!readonly"
                      [class.bg-gray-400]="readonly"
                      [class.cursor-not-allowed]="readonly"
                      [disabled]="readonly">
                <mat-icon class="mr-2" *ngIf="!readonly">add_circle</mat-icon>
                <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
                <span>Registrar Correcciones</span>
              </button>

            </div>

            <!-- Corrección existente -->
            <div *ngIf="correccion != null" class="p-4 bg-white rounded-md shadow-lg mb-4 transition-transform hover:shadow-2xl flex-grow flex flex-col">
              <div class="flex justify-between items-center mb-4">
                <div class="flex items-center space-x-2">
                  <mat-icon class="text-gray-500">edit</mat-icon>
                  <p class="text-md font-bold text-gray-700">Corrección #{{ correccion.id }}</p>
                </div>

                <!-- Botones Editar y Eliminar (bloqueados si es readonly) -->
                <div class="flex space-x-2">
                  <!-- Botón Editar (solo visible si es permitido editar) -->
                  <button *ngIf="detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1" mat-raised-button color="accent"
                          (click)="editCorrection(correccion.id)" [disabled]="readonly">
                    <mat-icon class="mr-1">edit</mat-icon>
                    Editar
                  </button>

                  <!-- Botón Eliminar -->
                  <button *ngIf="detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1" mat-raised-button color="warn"
                          (click)="deleteCorrection(correccion.id)" [disabled]="readonly">
                    <mat-icon class="mr-1">delete</mat-icon>
                    Eliminar
                  </button>
                </div>
              </div>

              <!-- Información de la corrección -->
              <div class="grid grid-cols-1 gap-2 text-gray-500 text-sm">
                <div class="flex items-center">
                  <mat-icon class="mr-1">calendar_today</mat-icon>
                  <span class="font-bold">Creación:</span>
                  <span class="ml-1">{{ correccion.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                </div>
                <div class="flex items-center">
                  <mat-icon class="mr-1">update</mat-icon>
                  <span class="font-bold">Ult. modificación:</span>
                  <span class="ml-1">{{ correccion.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                </div>
                <div class="flex items-center">
                  <mat-icon class="mr-1">person</mat-icon>
                  <span class="font-bold">Ult. operador:</span>
                  <span class="ml-1">{{ correccion.idOperador }}</span>
                </div>
              </div>

              <!-- Problemas asociados -->
              <div class="mt-4 flex-grow">
                <div class="font-bold text-gray-700 mb-2 flex items-center">
                  <mat-icon class="mr-1">error_outline</mat-icon>
                  Problemas asociados:
                </div>

                <!-- Contenedor con scroll para la lista de problemas si sobrepasa la altura -->
                <div class="overflow-y-auto" style="max-height: calc(100vh - 500px);">
                  <ul class="space-y-2">
                    <li *ngFor="let problema of correccion.problemas" class="bg-gray-50 p-2 rounded-lg shadow-md">
                      <!-- Encabezado: Nombre del problema e ícono de ayuda alineados -->
                      <div class="flex items-center justify-between">
                        <div class="flex items-center space-x-2">
                          <p class="font-semibold text-gray-800">{{ getMotivoById(problema.idMotivo).nombre }}</p>
                          <!-- Ícono de ayuda con tooltip para la descripción del motivo -->
                          <mat-icon matTooltip="{{ getMotivoById(problema.idMotivo).descripcion }}" class="text-blue-500 text-sm cursor-pointer">help_outline</mat-icon>
                        </div>
                      </div>

                      <!-- "No Solucionable" alineado con ícono de ayuda -->
                      <div *ngIf="!getMotivoById(problema.idMotivo).solucionable" class="flex items-center space-x-2 mt-1">
                        <p class="text-xs text-red-600 font-semibold">No Solucionable</p>
                        <!-- Ícono de ayuda para explicar que es "No Solucionable" -->
                        <mat-icon matTooltip="Este problema no puede ser solucionado por el operador. La revisión se marcará como fallida y requerirá intervención adicional." class="text-red-500 text-sm cursor-pointer">help_outline</mat-icon>
                      </div>

                      <!-- Mostrar valores cuando es Solucionable -->
                      <div *ngIf="getMotivoById(problema.idMotivo).solucionable" class="mt-1 text-xs text-gray-600">
                        <p><strong>Valor Anterior:</strong> {{ problema.valorAnterior ?? 'N/A' }}</p>
                        <p><strong>Valor Nuevo:</strong> {{ problema.valorNuevo ?? 'N/A' }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </ng-container>

        </div>

        <!-- Botones Inferiores -->
        <div *ngIf="!isLoading && !isSaving && !isCreatingNewCorrection && !isEditingCorrection && !hideBottomButtons" class="mt-auto flex justify-end space-x-4">
          <button type="button" class="bg-gray-300 hover:bg-gray-500 text-black py-2 px-4 rounded-md text-sm flex items-center" [mat-dialog-close]="true">
            <mat-icon class="mr-1">close</mat-icon>
            Cerrar ventana
          </button>

          <!-- Botón Reabrir Revisión (bloqueado si es readonly) -->
          <button *ngIf="this.detalleRevision.estadoId == 2 || this.detalleRevision.estadoId == 3"
                  (click)="reopenRevision()" type="button"
                  class="text-white py-2 px-4 rounded-md text-sm flex items-center justify-center"
                  [class.bg-orange-600]="!readonly"
                  [class.hover\:bg-orange-700]="!readonly"
                  [class.bg-gray-400]="readonly"
                  [class.cursor-not-allowed]="readonly"
                  [disabled]="readonly">
            <mat-icon class="mr-2" *ngIf="!readonly">folder_open</mat-icon>
            <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
            <span >Reabrir revisión</span>
          </button>

          <!-- Botón Finalizar Revisión (bloqueado si es readonly) -->
          <button *ngIf="this.detalleRevision.estadoId == 1 || this.detalleRevision.estadoId == 5"
                  (click)="closeRevision()" type="button"
                  class="text-white py-2 px-4 rounded-md text-sm flex items-center justify-center"
                  [class.bg-blue-600]="!readonly"
                  [class.hover\:bg-blue-700]="!readonly"
                  [class.bg-gray-400]="readonly"
                  [class.cursor-not-allowed]="readonly"
                  [disabled]="readonly">
            <mat-icon class="mr-2" *ngIf="!readonly">done</mat-icon>
            <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
            <span *ngIf="detalleRevision.totalCorrecciones == 0">Finalizar revisión sin correcciones</span>
            <span *ngIf="detalleRevision.totalCorrecciones > 0">Finalizar revisión con correcciones</span>
          </button>


        </div>
      </div>

    </div>
  </div>
</div>
