import { Injectable } from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "../../../../base/constants/constants";
import {PaginationOptions} from "../../../models/paginationOptions";
import {estadosRevisionCorreccion} from "../constants/EstadosRevisionCorreccion";
import {ProblemaCorreccionDTO} from "../dto/problemaCorreccionDTO";
import {RevisionFilter} from "../qa-grabaciones/filters/revision-filter";

@Injectable({
  providedIn: 'root'
})
export class QaGrabacionesService {

  estadosFiltro = estadosRevisionCorreccion;


  constructor(
    private http: HttpClient
  ) { }
  getEstadoById(id: number) {
    for (let estado of Object.values(this.estadosFiltro)) {
      if (estado.id == id) {
        return estado;
      }
    }
    return this.estadosFiltro.DESCONOCIDO;
  }

  getListaRevisionesPorRut(options: PaginationOptions, filtros?: RevisionFilter): Observable<any> {
    let params = new HttpParams();
    params = params.set('pageNum', options.page);
    params = params.set('pageSize', options.size);

    // Agregar los filtros opcionales desde la clase de filtros
    if (filtros) {
      params = params.set('mostrarArchivados', filtros.mostrarArchivados.toString());
      if (filtros.rutDocente) {
        params = params.set('rutDocente', filtros.rutDocente.toString());
      }
      if (filtros.estadoRevision) {
        params = params.set('estadoRevision', filtros.estadoRevision.toString());
      }
      if (filtros.mostrarAsignadas){
        params = params.set('mostrarAsignadas', filtros.mostrarAsignadas.toString());
      }
    }

    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones`;
    return this.http.get(url, { params: params });
  }

  deleteCorreccion(id: number): Observable<void> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/correcciones/${id}`;
    return this.http.delete<void>(url)
  }


  getDetalleRevision(revisionId: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${revisionId}`;
    return this.http.get(url);
  }

  cerrarRevision(revisionId: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${revisionId}/cerrar`;
    return this.http.post(url, {},);
  }

  reabrirRevision(revisionId: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${revisionId}/reabrir`;
    return this.http.put(url, {},);
  }

  // Crear una nueva corrección para una revisión específica
  iniciarCorreccion(idRevision: number, correccionData: any): Observable<any> {
    const problemas = correccionData?.problemas.map((problema:any) => {
      const dto = new ProblemaCorreccionDTO();
      dto.valorNuevo = problema.valorNuevo;
      dto.valorAnterior = problema.valorAnterior;
      dto.idMotivo = problema.motivoId;
      return dto;
    });
    const requestBody = {problemas: problemas};
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${idRevision}/correcciones`;
    return this.http.post(url, requestBody);
  }

  // Editar una corrección para una revisión específica
  editarCorreccion(idRevision: number, idCorreccion: number, correccionData: any): Observable<any> {
    console.log("correccionData:", correccionData)
    const problemas = correccionData?.problemas.map((problema:any) => {
      const dto = new ProblemaCorreccionDTO();
      dto.valorNuevo = problema.valorNuevo;
      dto.valorAnterior = problema.valorAnterior;
      dto.idMotivo = problema.motivoId;
      return dto;
    });
    const requestBody = {problemas: problemas};
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${idRevision}/correcciones/${idCorreccion}`;
    return this.http.put(url, requestBody);
  }

  // Obtener la lista de todas las correcciones de una revisión
  obtenerCorreccionesPorRevision(idRevision: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/qa/revisiones/${idRevision}/correcciones`;
    return this.http.get(url);
  }
}
