import { DatePipe, formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerControl, MatDatepickerInputEvent, MatDatepickerPanel } from '@angular/material/datepicker';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import Swal from 'sweetalert2';
import { Comuna } from '../models/Comuna';
import { Region } from '../models/Region';
import { RegistroSeguimiento } from '../models/RegistroSeguimiento';
import { FichaSeguimientoService } from '../services/ficha-seguimiento.service';
import { copyToClipboard } from '../services/copyToClipboard';
import { Constants } from 'src/app/base/constants/constants';
import { Toast } from 'src/app/base/services/toast';
import { Nomina } from '../models/nomina';
import { DocenteNomina } from '../models/docenteNomina';

@Component({
  selector: 'app-ficha-seguimiento',
  templateUrl: './ficha-seguimiento.component.html',
  styleUrls: ['./ficha-seguimiento.component.css'],
  providers: [DatePipe],
})
export class FichaSeguimientoComponent implements OnInit, AfterViewInit{

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('cerrarFormModal') cerrarFormModal: ElementRef;
  @ViewChild('abrirModalNomina') abrirModalNomina: ElementRef;
  esconderAnimacion!: boolean;
  archivoAnimacionPrincipal = "startfichaseguimiento.json";
  archivoAnimacionAccion = "ficha-seguimiento.json";
  faSpinner = faSpinner;
  pageIndex: number;
  length: number;
  options: PaginationOptions = new PaginationOptions();
  isLoading!: boolean;
  isLoadingNominas!: boolean;
  response: any;
  descargandoCsv!: boolean;
  filtros: RegistroSeguimiento = new RegistroSeguimiento();
  nuevaFicha: RegistroSeguimiento = new RegistroSeguimiento();
  dataSource: MatTableDataSource<RegistroSeguimiento> = new MatTableDataSource();
  today = new Date();
  regiones!: Region[];
  comunas!: Comuna[];
  docentesEnNomina!: DocenteNomina[];
  rutSostenedorSeleccionado!: number;
  nombreSostenedorSeleccionado!: string;

  // validaciones
  minDate = new Date("Saturday, 1 March 2024 0:00:00");
  estaDescargandoExcel!: boolean;

  // crear/editar
  @ViewChild('inputNumSeguimiento') inputNumSeguimiento!: ElementRef;
  formularioValido!: boolean;
  checkNumeroSeguimiento: boolean = null;
  numeroSeguimientoActual!: string;
  formularioEdicionOriginal!: RegistroSeguimiento;
  numeroSeguimientoEdicion!: string;
  sostenedorEdicion!: number;
  nombreSostenedor!: string;
  nominasSostenedor!: Nomina[];
  idNominaPrevio!: number;
  permiteEditar!: boolean;

  estados: {label: string, value: number}[] = [
    {label: "En poder del Sostenedor", value:0},
    {label: "En trayecto", value: 1}
  ];
  pickerCreate: MatDatepickerPanel<MatDatepickerControl<any>,any,any>;
  fichaForm!: FormGroup;
  verDatosEdicion: boolean = false;
  permiteEditarNumSeguimiento: boolean = true;

  constructor(
    private fichaService: FichaSeguimientoService,
    private errorMensaje: ErrorMensajeService,
    private permisoPara: AutorizacionesService,
    private datePipe: DatePipe
  ){
    this.fichaForm = new FormGroup({
      idFicha: new FormControl(""),
      numSeguimiento: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?!.*[eE-])\d{12}$/)
      ]),
      rutSostenedor: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?:\d{7,8})$/)
      ]),
      idRegion: new FormControl('', Validators.required),
      comuna: new FormControl('', Validators.required),
      idEstadoEnvio: new FormControl('', Validators.required),
      fechaEnvio: new FormControl(''),
      idNomina: new FormControl(''),
    });
    this.fichaForm.updateValueAndValidity();
  }

  ngAfterViewInit() {
    if(!this.paginator) return;
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;

  }

  iniciarConsulta() {
    this.isLoading = true;
    this.esconderAnimacion = false;
  }

  finalizarConsulta() {
    this.isLoading = false;
    setTimeout(() => {
      this.esconderAnimacion = true;
    }, 2000);
  }

  ngOnInit(): void {
    this.iniciarConsulta();
    this.esconderAnimacion = false;
    this.getRegiones();
    this.getComunas();
    this.getFichaSeguimiento();
    this.fichaForm?.get('numSeguimiento')?.valueChanges.subscribe(value => {
      this.checkearNumeroSeguimiento(value);
    });
    this.fichaForm?.get('rutSostenedor')?.valueChanges.subscribe(value => {
      this.validaRutSostenedorAlCrear(value);
    });
    this.fichaForm?.get('idNomina')?.valueChanges.subscribe(value => {
      this.docentesEnNomina = null;
      if (!value) return;
      this.obtenerDocentesEnNomina(value, this.fichaForm.value.rutSostenedor, this.nombreSostenedor, false);
    });


  }

  mostrarDocentesNomina(idNomina: number, rutSostenedor:number, nombreSostenedor: string) {
    if (!idNomina || !rutSostenedor) return;
    this.obtenerDocentesEnNomina(idNomina, rutSostenedor, nombreSostenedor);

  }

  abrirModal() {
    this.getComunas();
    this.formularioEdicionOriginal = null;
    this.numeroSeguimientoEdicion = null;
    this.numeroSeguimientoActual = null;
    this.sostenedorEdicion = null;
    this.nuevaFicha = new RegistroSeguimiento();
    this.checkNumeroSeguimiento = null;
    this.comunas =
    this.nombreSostenedor = null;
    this.nominasSostenedor = null;
    this.permiteEditar = true;
    this.verDatosEdicion = false;
    this.fichaForm.reset();
    this.permiteEditarNumSeguimiento = true;
  }

  crearFicha() {
    if (this.fichaForm.valid) {
      const registro: RegistroSeguimiento = this.fichaForm.getRawValue();
      const fecha = registro.idEstadoEnvio == 1 ? new Date().toISOString() : null;
      this.iniciarConsulta();;
      this.fichaService.crearNuevaFicha(registro, fecha)
      .subscribe({
        next : (response: any) => {
          Swal.fire("Ficha creada",response.status,"success");
          this.limpiar();
          this.finalizarConsulta();
        },
        error: (err: any) => {
          this.informarError(err, 'crear esta ficha');
        }
      });
    } else {
    }
  }

  eliminar(ficha: RegistroSeguimiento) {
    if (!ficha) {
      return;
    }
    this.isLoading = true;
    this.fichaService.fichaTieneBulto(ficha.idFicha)
    .subscribe(
      {
        next: (value: any | boolean | null) => {
          this.isLoading = false;
            if (null == value) {
              Swal.fire("Ups","Estamos teniendo problemas para procesar su petición", "warning");
              return;
            } else {
              if (value) {
                Swal.fire("Eliminación detenida","Hay bultos asociados a esta ficha, elimínelos antes de borrar este registro", "info");
                return;
              }
              Swal.fire({
                title: '¿Desea eliminar esta Ficha?',
                text: 'Este proceso no se puede deshacer',
                icon: 'warning',
                showDenyButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar',
                confirmButtonColor: '#d9534f',
                cancelButtonColor: '#0275d8',

              }).then((result) => {
                if (result.isConfirmed) {
                  this.eliminarFicha(ficha)
                }
              })
            }
        },
        error: (err: any) => {
          this.informarError(err, "consultar bultos de fichas")
        },
      }
    )

  }


  eliminarFicha(ficha: RegistroSeguimiento) {
    this.fichaService.eliminar(ficha)
    .subscribe({
      next: (response: any) => {
        Swal.fire("Eliminación","Ficha eliminada con éxito","success");
        this.limpiar();
      },
      error: (err: any) => {
        this.informarError(err, 'eliminar esta ficha');
      }
    });
  }

  abrirModalEdicionFicha(ficha: RegistroSeguimiento) {
    this.permiteEditar = false;
    this.fichaService.fichaTieneBulto(ficha.idFicha)
    .subscribe(
      {
        next: (value: any | boolean | null) => {
          this.isLoading = false;
            if (null == value) {
              Swal.fire("Ups","Estamos teniendo problemas para procesar su petición", "warning");
              this.permiteEditar = false;
              return;
            } else {
              if (value == true) {
                this.permiteEditar = false;
                this.permiteEditarNumSeguimiento = false;
                this.checkNumeroSeguimiento = true;
                this.verDatosEdicion = true;
                Swal.fire("Edición detenida","Hay bultos asociados a esta ficha, elimínelos antes de editar este registro", "info");
                return;
              }
              this.getComunas();
              this.checkNumeroSeguimiento = true;
              this.permiteEditarNumSeguimiento = false;
              this.permiteEditar = true;
              this.verDatosEdicion = true;
            }
        },
        error: (err: any) => {
          this.informarError(err, "consultar bultos de fichas")
        },
      }
    )
    delete ficha['estadoEnvio'];
    this.numeroSeguimientoActual = ficha.numSeguimiento;
    this.nombreSostenedor = null;
    this.nominasSostenedor = null;
    this.formularioEdicionOriginal = ficha;
    this.numeroSeguimientoEdicion = ficha.numSeguimiento;
    this.sostenedorEdicion = ficha.rutSostenedor;
    this.fichaForm.setValue(
      {
        "comuna": ficha.comuna,
        "fechaEnvio": ficha.fechaEnvio,
        "idEstadoEnvio": ficha.idEstadoEnvio,
        "idFicha": ficha.idFicha,
        "idRegion": ficha.idRegion,
        "numSeguimiento": ficha.numSeguimiento,
        "rutSostenedor": ficha.rutSostenedor,
        "idNomina": null
      }
    );
    this.inputNumSeguimiento.nativeElement.focus();
    this.fichaForm.patchValue({"idNomina": ficha.idNomina}, {emitEvent:false});
    this.idNominaPrevio = ficha.idNominaPrevio;
    this.checkNumeroSeguimiento = true;
  }

  editarFicha() {
    const ficha: RegistroSeguimiento = this.fichaForm.getRawValue();
    ficha.idNominaPrevio = this.idNominaPrevio;
    const matches = JSON.stringify(ficha) === JSON.stringify(this.formularioEdicionOriginal);
    if (matches) {
      Swal.fire("Edición de Ficha","No hay nuevos cambios en los datos. La edición no se realizó.","warning");
      return;
    }
    this.isLoading = true;
    this.iniciarConsulta();
    this.fichaService.editarFicha(ficha)
    .subscribe({
      next: (response: any) => {
        Swal.fire("Edición de Ficha","Ficha editada con éxito","success");
        this.finalizarConsulta();
        this.limpiar();
      },
      error: (err: any) => {
        this.informarError(err, 'editar esta ficha');
      }
    });

  }

  obtenerDocentesEnNomina(idNomina: number, rutSostenedor: number, nombreSostenedor: string, abrirModal: boolean = true) {
    this.docentesEnNomina = null;
    this.isLoadingNominas = true;
    this.fichaService.docentesEnNomina(idNomina)
    .subscribe({
      next: (docentes: DocenteNomina[] | any) => {
        this.docentesEnNomina = docentes;
        this.rutSostenedorSeleccionado = rutSostenedor;
        this.nombreSostenedorSeleccionado = nombreSostenedor;
        this.isLoadingNominas= false;
        if (abrirModal) this.abrirModalNomina.nativeElement.click();
      },
      error: (err: any) =>{
        this.isLoadingNominas= false;
        this.informarError(err, 'consultar los docentes de la nómina');
      },
    });
  }

  validaDataNumSeguimiento() {
    if (this.numeroSeguimientoActual == this.fichaForm.value.numSeguimiento) this.checkNumeroSeguimiento = true;
    else this.numeroSeguimientoActual == this.fichaForm.value.numSeguimiento
  }

  validaRutSostenedorAlCrear(inputRut: string) {
    const regex = /^\d{8}$/;
    if (!inputRut || !regex.test(inputRut)) {
      this.nombreSostenedor = null;
      this.nominasSostenedor = null;
      return;
    }
    const rut:number = Number(inputRut);

    this.fichaService.checkRutRepresentante(rut)
    .subscribe({
      next: (response: any) => {
        this.nombreSostenedor = !response.check?.nombre ? "": response.check.nombre;
        this.consultarNominas(rut);
      },
      error: (err: any) => {
        this.informarError(err, 'validar el rut del sostenedor / representante legal');
        this.nominasSostenedor = null;
      }
    });
  }

  consultarNominas(rut: number) {
    this.isLoadingNominas = true;
    this.fichaService.nominasSostenedor(rut)
    .subscribe({
      next: (response:Nomina[]) => {
        this.isLoadingNominas = false;
        this.nominasSostenedor = response;
      },
      error: (err: any ) => {
        this.informarError(err, 'consultar las nóminas del sostenedor');
        this.isLoadingNominas = false;
        this.nominasSostenedor = null;
      }
    });
  }

  checkearNumeroSeguimiento(numeroSeguimiento: string) {
    if(this.verDatosEdicion && !this.permiteEditar){
      return
    }
    if(!numeroSeguimiento || !numeroSeguimiento.trim()) {
      this.checkNumeroSeguimiento = false;
      return;
    }
    numeroSeguimiento = numeroSeguimiento?.trim()?.replace("-","")?.replace(".","")?.replace(".","");
    this.fichaForm.patchValue({numSeguimiento: numeroSeguimiento}, {emitEvent:false});
    const regex = /^(?!.*[eE-])\d{12}$/;
    if (numeroSeguimiento.length == 12 && regex.test(numeroSeguimiento) && this.numeroSeguimientoEdicion &&
      numeroSeguimiento == this.numeroSeguimientoEdicion) {
      this.checkNumeroSeguimiento = true;
      return;
    }
    if (numeroSeguimiento.length!=12 || !regex.test(numeroSeguimiento)) {
      this.checkNumeroSeguimiento = false;
      return;
    }

    this.fichaService.checkNumeroSeguimiento(numeroSeguimiento)
    .subscribe({
      next: (check: any) => {
        this.checkNumeroSeguimiento = null;
        if (true  == check) {
          this.checkNumeroSeguimiento = true;
          this.numeroSeguimientoActual = numeroSeguimiento;
        }
        if (false == check) this.checkNumeroSeguimiento = false;
      },
      error: (err) => {
        this.informarError(err, 'validar el número de seguimiento');
      },
    });
  }

  verificaRegionSegunComunaSeleccionada(event: MatSelectChange | Event) {
    let comunaSeleccionada: string = null;
    if( event instanceof MatSelectChange) {
      if (!event.value) return
      comunaSeleccionada = event.value;
    } else {
      if(!(event.target as HTMLSelectElement).value) return;
      comunaSeleccionada = (event.target as HTMLSelectElement).value;
    }
    const comuna: Comuna[] = this.comunas.filter((item: Comuna) =>
      (item.comuna.toLowerCase() == comunaSeleccionada.toLowerCase()))
    if( event instanceof MatSelectChange) this.filtros.idRegion = comuna[0]?.idRegion;
    else  this.nuevaFicha.idRegion = comuna[0]?.idRegion;
  }

  verificaRegionSegunComunaSeleccionadaModal(event: MatSelectChange) {
    let comunaSeleccionada: string = null;
    if (!event.value) return
    comunaSeleccionada = event.value;
    const comuna: Comuna[] = this.comunas.filter((item: Comuna) =>
      (item.comuna.toLowerCase() == comunaSeleccionada.toLowerCase()));
    this.fichaForm.patchValue({"idRegion" : comuna[0]?.idRegion});
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    if (date) {
      const formattedDate = formatDate(date, 'dd-MM-yyyy', 'en-US');

      const storageDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    }
  }

  copiarData(data:any) {
    if (data == null || data == undefined) return;
    copyToClipboard(data)
  }

  getComunaPorRegion(event: MatSelectChange | Event) {
    let idRegion: number = null;
    if( event instanceof MatSelectChange) {
      if (!event.value) return
      idRegion = event.value;
    } else {
      if(!(event.target as HTMLSelectElement).value) return;
      idRegion = Number((event.target as HTMLSelectElement).value);
    }
    this.fichaService.getComunas()
    .subscribe({
      next: (response: Comuna[]) => {
        this.comunas = response.filter((item: Comuna) => (item.idRegion == idRegion));
      },
      error: (err) => {
        this.finalizarConsulta();
        this.informarError(err, 'obtener las comunas');
      },
    });
  }

  getComunas() {
    this.fichaService.getComunas()
    .subscribe({
      next: (response: Comuna[]) => {
        this.comunas = response;
      },
      error: (err) => {
        this.informarError(err, 'obtener las comunas');
      },
    });
  }

  getRegiones() {
    this.fichaService.getRegiones()
    .subscribe({
      next: (response: Region[]) => {
        this.regiones = response;
      },
      error: (err) => {
        this.informarError(err, 'obtener las regiones');
      },
    });
  }

  getEstadoEnvio() {

  }

  getFichaSeguimiento() {
    this.iniciarConsulta();
    const fechaEnvio = this.filtros.fechaEnvio ?
      this.datePipe.transform(this.filtros.fechaEnvio, 'yyyy-MM-dd HH:mm:ss Z').toString() : null
    this.fichaService.getListaFichaSeguimiento(this.options,this.filtros, fechaEnvio)
    .subscribe({
      next: (response: any) => {
        this.dataSource = new MatTableDataSource<RegistroSeguimiento>(response.list);
        this.response = response.list;
        this.pageIndex = (response.pageNum - 1);
        this.length = response.total;
        this.finalizarConsulta();
      },
      error: (err: any) => {
        this.informarError(err, 'obtener las fichas de seguimiento');
      },
    });
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getFichaSeguimiento();
  }

  public limpiar(): void {
    this.getComunas();
    this.filtros = new RegistroSeguimiento();
    this.cerrarFormModal.nativeElement.click();
    this.formularioEdicionOriginal = null;
    this.numeroSeguimientoEdicion = null;
    this.sostenedorEdicion = null;
    this.nominasSostenedor = null;
    this.filtrar();
  }

  descargarFichas(){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/ficha/csv/`;
    const filename = "listaFichaSeguimiento.csv"
    this.estaDescargandoExcel = true;
    const fechaEnvio = this.filtros.fechaEnvio ?
    this.datePipe.transform(this.filtros.fechaEnvio, 'yyyy-MM-dd HH:mm:ss Z').toString() : null

    this.fichaService.descargarFichas(url, this.filtros, fechaEnvio)
    .subscribe(
      {
        next: (response: any) => {
          Toast.fire(
            "Descarga de fichas de seguimiento",
            "Descarga finalizada",
            "success"
          );
          this.fichaService.downloadBlob(filename, response);
          this.estaDescargandoExcel = false;
        }, error: (err: any) => {
          this.informarError(err, 'descargar las fichas de seguimiento');
          this.estaDescargandoExcel = false;
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getFichaSeguimiento();
  }

  estadoEnvio(value:number) {
    const label = this.estados.filter( (item: {label: string, value: number}) => item.value == value);
    return label[0]?.label;
  }

  informarError(err: any, contexto: string) {
    this.errorMensaje.show(err, contexto).then((result:any) => {
      if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
        this.finalizarConsulta();
      }
    });
  }
}
