export default [
  {
    id: 1,
    nombre: 'No se visualiza bien',
    solucionable: false,
    descripcion: 'El video presenta problemas de visualización que afectan su claridad.'
  },
  {
    id: 2,
    nombre: 'Problema de sonido',
    solucionable: false,
    descripcion: 'El audio presenta distorsiones o no es claro durante la reproducción.'
  },
  {
    id: 3,
    nombre: 'RUT no corresponde',
    solucionable: true,
    descripcion: 'El RUT asignado no coincide con la información esperada.'
  },
  {
    id: 4,
    nombre: 'Correlativo no corresponde',
    solucionable: true,
    descripcion: 'El número correlativo de la revisión no coincide con el esperado.'
  },
  {
    id: 5,
    nombre: 'Duración incorrecta',
    solucionable: false,
    descripcion: 'La duración del video no se ajusta a la especificada en el contenido.'
  },
  {
    id: 6,
    nombre: 'Formato incorrecto',
    solucionable: false,
    descripcion: 'El formato del archivo de video no es compatible o no es el esperado.'
  },
  {
    id: 7,
    nombre: 'Faltan secciones',
    solucionable: false,
    descripcion: 'Algunas partes del video no están presentes o se encuentran incompletas.'
  },
  {
    id: 8,
    nombre: 'Reproducción interrumpida',
    solucionable: false,
    descripcion: 'El video se detiene inesperadamente o presenta cortes durante su reproducción.'
  },
  {
    id: 9,
    nombre: 'Coloración incorrecta',
    solucionable: false,
    descripcion: 'Los colores del video no corresponden con los originales o esperados.'
  },
  {
    id: 10,
    nombre: 'Baja resolución',
    solucionable: false,
    descripcion: 'La calidad del video es baja, lo que afecta la visibilidad del contenido.'
  },
  {
    id: 11,
    nombre: 'Velocidad incorrecta',
    solucionable: false,
    descripcion: 'El video se reproduce a una velocidad diferente a la especificada.'
  },
  {
    id: 12,
    nombre: 'Marca de agua incorrecta',
    solucionable: false,
    descripcion: 'La marca de agua en el video es incorrecta o no corresponde al contenido.'
  },
  {
    id: 13,
    nombre: 'Video no corresponde a clase grabada',
    solucionable: false,
    descripcion: 'El contenido del video no tiene relación alguna con la clase o tema que debía ser grabado, mostrando material completamente fuera de contexto.'
  }
];
