import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginationOptions } from "src/app/registros/models/paginationOptions";
import { RecepcionFiltro } from "../models/RecepcionFiltro";
import { Constants } from "src/app/base/constants/constants";
import { Bulto } from "../models/Bulto";
import { AuthService } from "src/app/base/services/auth.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecepcionBultoService {
  constructor(
    private http: HttpClient,
    public authService: AuthService,
  ) { }
  bultosList(options: PaginationOptions,
    filtros: RecepcionFiltro
  ){
    let params = this.obtenerParams(filtros);
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.LISTAR}?page=${options.page}&size=${options.size}`;
    return this.http.get(url, {params:params});
  }

  agregarBulto(folio:string,
    seguimiento:string,
    fechaEnvio:string,
    estado:number,
    observacion:string
  ){
    let user = this.authService.getEmailUser();
    let bulto = new Bulto;
    bulto.folio = folio;
    bulto.seguimiento = seguimiento;
    bulto.fecha = fechaEnvio;
    bulto.estado = estado;
    bulto.observaciones = observacion;
    bulto.operador = user;
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.AGREGAR}`;
    return this.http.post(url, bulto);

  }

  obtenerParams(filtros: RecepcionFiltro) {
    let params = new HttpParams();

    if (filtros.folio && filtros.folio.trim() != "") {
      params = params.set('folio',filtros.folio);
    }
    if (filtros.seguimiento && filtros.seguimiento.trim() != "") {
      params = params.set('seguimiento',filtros.seguimiento)
    }
    if (filtros.rutSostenedor && filtros.rutSostenedor > 0) {
      params = params.set('rutSostenedor',filtros.rutSostenedor);
    }
    if (filtros.estado && filtros.estado > 0) {
      params = params.set('estado',filtros.estado);
    }
    return params;
  }

  eliminarBulto(seguimiento: string){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.ELIMINAR}/${seguimiento}`;
    return this.http.delete(url);

  }

  editarBulto(id:number,
    folio:string,
    seguimiento:string,
    fechaEnvio:string,
    estado:number,
    observacion:string
  ){
    let user = this.authService.getEmailUser();
    let bulto = new Bulto;
    bulto.id = id;
    bulto.folio = folio;
    bulto.seguimiento = seguimiento;
    bulto.fecha = fechaEnvio;
    bulto.estado = estado;
    bulto.observaciones = observacion;
    bulto.operador = user;
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.EDITAR}`;
    return this.http.put(url, bulto);

  }

  checkNumeroSeguimiento(numero: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.CHECKSEGUIMIENTO}`;
    return this.http.get(url, {params: {numSeguimiento: `'${numero}'`}});
  }

  checkNumeroSeguimientoBulto(numero: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.CHECKSEGUIMIENTOBULTO}`;
    return this.http.get(url, {params: {numSeguimiento: `'${numero}'`}});
  }
  checkFolio(numero: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.CHECKFOLIO}`;
    return this.http.get(url, {params: {folio: `'${numero}'`}});
  }

  checkCaja(numero: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES + Constants.URL_SERVICE.RECEPCION_BULTO.CHECKCAJA}`;
    return this.http.get(url, {params: {folio: `'${numero}'`}});
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  descargarBultos(url: string, filtro: RecepcionFiltro): Observable<any> {
    let params = this.obtenerParams(filtro);
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }
}
