import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QaGrabacionesService } from "../../../services/qaGrabacionesService";
import motivosProblemasCorrecciones from "../../../constants/motivos-problemas-correcciones"
import {AlertsService} from "../../../../../../base/services/alerts.service";

@Component({
  selector: 'app-nueva-correccion',
  templateUrl: './nueva-correccion.component.html',
  styleUrls: ['./nueva-correccion.component.css']
})
export class NuevaCorreccionComponent implements OnInit {
  correccionForm: FormGroup;
  motivos = motivosProblemasCorrecciones;
  motivosFiltrados = motivosProblemasCorrecciones;
  problemas: any[] = [];
  mostrarCamposValor: boolean = false;
  loading: boolean = false;
  saving: boolean = false

  @Output() guardarCorreccion = new EventEmitter<{ problemas: any[] }>();
  @Output() cancelarCorreccion = new EventEmitter<void>();
  @Input() revisionData: any;
  @Input() edit: boolean = false;

  selectedMotivo: any = null;

  constructor(
    private fb: FormBuilder,
    private service: QaGrabacionesService,
    private alerts: AlertsService
  ) {
    this.correccionForm = this.fb.group({
      motivo: [null, Validators.required],
      valorAnterior: [{ value: '' }],  // Deshabilitado hasta que se necesite
      valorNuevo: ['']
    });

  }

  ngOnInit(): void {
    this.filtrarMotivos();
    if (this.edit) {
      this.loading = true;  // Mostrar spinner durante la carga
      this.cargarDatosCorreccion();  // Cargar datos en modo edición
    }
  }

  // Filtro que actualiza los motivos disponibles
  filtrarMotivos(): void {
    const motivosSeleccionados = this.problemas.map(p => p.motivoId);
    this.motivosFiltrados = this.motivos.filter(m => !motivosSeleccionados.includes(m.id));
  }

  getMotivoById(id: number): any | null {
    return this.motivos.find(motivo => motivo.id === id) || null;
  }

  onMotivoChange(motivoId: number | null): void {
    this.selectedMotivo = this.motivos.find(m => m.id === motivoId);
    if(!this.selectedMotivo){
      return;
    }
    if (this.selectedMotivo.solucionable) {
      this.mostrarCamposValor = true;
      this.correccionForm.get('valorNuevo')?.setValidators(Validators.required);
      this.correccionForm.get('valorNuevo')?.updateValueAndValidity();

      if (this.selectedMotivo.id === 3) {
        this.correccionForm.get('valorAnterior')?.setValue(this.revisionData.rutDocente);
      } else if (this.selectedMotivo.id === 4) {
        this.correccionForm.get('valorAnterior')?.setValue(this.revisionData.ordenCorrelativo);
      }
    } else {
      this.correccionForm.get('valorNuevo')?.clearValidators();
      this.correccionForm.get('valorNuevo')?.updateValueAndValidity();
      this.mostrarCamposValor = false;
      this.correccionForm.get('valorAnterior')?.reset();
      this.correccionForm.get('valorNuevo')?.reset();
    }
  }

  agregarProblema(): void {
    console.log("this.correccionForm.value:", this.correccionForm.value)
    if (this.correccionForm.valid) {
      const selectedMotivo = this.motivos.find(m => m.id === this.correccionForm.value.motivo);
      if (selectedMotivo) {
        const nuevoProblema = {
          motivoId: selectedMotivo.id,
          motivoNombre: selectedMotivo.nombre,
          valorAnterior: this.correccionForm.value.valorAnterior || null,
          valorNuevo: this.correccionForm.value.valorNuevo || null,
          solucionable: selectedMotivo.solucionable,
        };
        this.problemas.push(nuevoProblema);
        this.correccionForm.reset();
        this.mostrarCamposValor = false;
        this.filtrarMotivos();
      }
    }
  }

  eliminarProblema(index: number): void {
    this.problemas.splice(index, 1);
    this.filtrarMotivos();
  }

  onGuardar(): void {
    this.saving = true;  // Mostrar spinner al guardar
    // Simular un retraso en la operación para mostrar el spinner
    setTimeout(() => {
      this.guardarCorreccion.emit({ problemas: this.problemas });
    }, 100);
  }

  onCancelar(): void {
    this.cancelarCorreccion.emit();
  }

  cargarDatosCorreccion(): void {
    this.service.obtenerCorreccionesPorRevision(this.revisionData.id).subscribe({
      next: (correccion) => {
        if (correccion) {
          this.problemas = correccion.problemas.map((problema: any) => {
            const motivo = this.motivos.find(m => m.id === problema.idMotivo);
            return {
              motivoId: problema.idMotivo,
              motivoNombre: motivo?.nombre || 'Desconocido',
              valorAnterior: problema.valorAnterior,
              valorNuevo: problema.valorNuevo,
              solucionable: motivo?.solucionable || false
            };
          });
          this.filtrarMotivos();
          this.loading = false;  // Ocultar el spinner una vez cargado
        }
      },
      error: (error) => {
        this.alerts.showExceptionAlert(error);
        console.error('Error al cargar la corrección:', error);
        this.loading = false;  // Ocultar el spinner si hay un error
        this.cancelarCorreccion.emit();

      }
    });
  }
}
